import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import * as yup from 'yup';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { captureException } from '@utils';
import {
  gql,
  useMutation,
} from '@apollo/client';

export default function ContactDialog(props) {
  const { contact, isOpen, onClose } = props;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [sendEmail] = useMutation(SEND_EMAIL_MUTATION);

  const handleSubmit = async (values) => {
    try {
      let targetEmail = contact.attributes.email || 'dominik.sollmann@klib-org.de';

      if (process.env.NODE_ENV === 'development') {
        console.log(targetEmail);
        targetEmail = 'info@dekay.dev';
      }

      const result = await sendEmail({
        variables: {
          name: values.name,
          targetEmail,
          senderEmail: values.email,
          message: values.message,
        },
      });

      enqueueSnackbar('Nachricht gesendet', {
        variant: 'success',
      });
      onClose();
    } catch (error) {
      console.error(error);
      captureException(error);
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }
  };

  return (
    <Dialog
      onClose={onClose}
      open={isOpen}
    >
      <Formik
        initialValues={INITIAL_VALUES}
        onSubmit={handleSubmit}
        validationSchema={VALIDATION_SCHEMA}
      >
        {(formikProps) => (
          <Form
            onClose={onClose}
            t={t}
            {...formikProps}
          />
        )}
      </Formik>
    </Dialog>
  );
}

function Form(props) {
  const {
    t,
    values,
    errors,
    touched,
    isValid,
    dirty,
    onClose,
    handleChange,
    handleSubmit,
    isSubmitting,
  } = props;

  return (
    <form onSubmit={handleSubmit}>
      <DialogTitle>
        Kontaktformular
      </DialogTitle>
      <DialogContent>
        <TextField
          error={errors.name && touched.name}
          fullWidth
          helperText={errors.name}
          label="Ihr Name"
          name="name"
          onChange={handleChange}
          size="small"
          sx={{ mt: 1 }}
          value={values.name}
        />
        <TextField
          error={errors.email && touched.email}
          fullWidth
          helperText={errors.email}
          label="Ihre E-Mail-Adresse"
          name="email"
          onChange={handleChange}
          size="small"
          sx={{ my: 3 }}
          value={values.email}

        />
        <TextField
          error={errors.message && touched.message}
          fullWidth
          helperText={errors.message}
          label="Ihre Nachricht"
          multiline
          name="message"
          onChange={handleChange}
          rows={3}
          size="small"
          value={values.message}
        />
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={onClose}
        >
          {t('shared.cancel')}
        </Button>
        <Button
          color="error"
          disabled={!dirty || !isValid || isSubmitting}
          type="submit"
          variant="contained"
        >
          Anfrage senden
        </Button>
      </DialogActions>
    </form>
  );
}

const INITIAL_VALUES = {
  name: '',
  email: '',
  message: '',
};

const VALIDATION_SCHEMA = yup.object().noUnknown().shape({
  name: yup.string().required(),
  email: yup.string().email().required(),
  message: yup.string().nullable(),
});

const SEND_EMAIL_MUTATION = gql`
  mutation SendEMail(
    $name: String!,
    $targetEmail: String!,
    $senderEmail: String!,
    $message: String!
  ) {
    dispatchContactMail(
      name: $name,
      targetEmail: $targetEmail,
      senderEmail: $senderEmail,
      message: $message
    )
  }
`;
