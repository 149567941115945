/* eslint-disable react/no-array-index-key */
import {
  Box,
  Typography,
} from '@mui/material';
import { Fragment } from 'react';
import TagLeave from './TagLeave';
import Seperator from './Seperator';

export default function TagRoot(props) {
  const {
    id,
    rootCount,
    rootIndex,
    name,
    items,
    hideTagRootTitle,
    renderColors,
    titleContainerProps,
    titleTypographyProps,
    leaveTypographyProps,
  } = props;

  return (
    <Box mb={rootIndex < rootCount - 1 ? 2 : 0}>
      {!hideTagRootTitle && (
        <Box {...titleContainerProps} mb={titleContainerProps?.mb ?? 1}>
          <Typography
            {...titleTypographyProps}
            sx={{ fontWeight: 'bold' }}
            variant={titleTypographyProps?.variant || 'subtitle2'}
          >
            {name}
          </Typography>
        </Box>
      )}
      {items.map((items2, index) => (
        <Box
          key={`${id}_${index}`}
          alignItems="center"
          display="flex"
          flexWrap="wrap"
        >
          {renderColors && (
            <Box
              mr={1}
              sx={{
                backgroundColor: items2[0]?.color || '#BDBDBD',
                borderRadius: '50%',
                height: 10,
                width: 10,
              }}
            />
          )}
          {items2.map((child, index2) => (
            <Fragment key={child.id}>
              <TagLeave
                leaveTypographyProps={leaveTypographyProps}
                name={child.name}
              />
              {index2 < items2.length - 1 && <Seperator />}
            </Fragment>
          ))}
        </Box>
      ))}
    </Box>
  );
}
