import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { FiExternalLink } from 'react-icons/fi';

export default function Link(props) {
  const { name, info, href } = props;

  return (
    <ListItem disablePadding>
      <ListItemButton
        component="a"
        href={href}
        sx={{ p: 0 }}
        target="_blank"
      >
        <ListItemIcon>
          <FiExternalLink />
        </ListItemIcon>
        <ListItemText
          primary={name}
          primaryTypographyProps={{
            color: 'primary',
            variant: 'body2',
          }}
          secondary={info}
        />
      </ListItemButton>
    </ListItem>
  );
}
