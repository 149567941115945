import { push } from '@socialgouv/matomo-next';

export function trackPageView(url) {
  push(['setCustomUrl', url]);
  push(['trackPageView']);
}

export function trackEvent() {
  push(['trackEvent', 'contact', 'click phone']);
}
