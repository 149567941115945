import 'photoswipe/dist/photoswipe.css';
import 'photoswipe-dynamic-caption-plugin/photoswipe-dynamic-caption-plugin.css';
import {
  useEffect,
  useRef,
} from 'react';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
  useTheme,
} from '@mui/material';
import { register } from 'swiper/element/bundle';
import { FiFile } from 'react-icons/fi';
import { Pagination } from 'swiper/modules';
import {
  Gallery,
  Item,
} from 'react-photoswipe-gallery';
import PhotoSwipeDynamicCaption from 'photoswipe-dynamic-caption-plugin';

register();

export default function Swiper(props) {
  const {
    items,
  } = props;
  const swiperElRef = useRef(null);
  const theme = useTheme();

  const swiperItems = items.map((seatingItem) => seatingItem);

  useEffect(() => {
    if (swiperElRef.current) {
      const params = {
        slidesPerView: 3,
        spaceBetween: 0,
        modules: [Pagination],
        pagination: {
          clickable: true,
          dynamicBullets: true,
        },
      };

      // assign all parameters to Swiper element
      Object.assign(swiperElRef.current, params);

      // init Swiper
      swiperElRef.current.initialize();
    }
  }, []);

  if (swiperItems.length === 0) {
    return null;
  }

  return (
    <Gallery
      plugins={(pswpLightbox) => {
        const captionPlugin = new PhotoSwipeDynamicCaption(pswpLightbox, {
          captionContent: renderCaption,
          type: 'auto',
        });
      }}
      withCaption
      withDownloadButton
    >
      <swiper-container
        ref={swiperElRef}
        auto-height="true"
        init="false"
      >
        {swiperItems.map((item) => {
          const image = item.attributes.file.data.attributes.formats?.small?.url
            ?? item.attributes.file.data.attributes.formats?.medium?.url
            ?? item.attributes.file.data.attributes.formats?.large?.url
            ?? item.attributes.file.data.attributes.url;

          return (
            <swiper-slide key={item.id}>
              <Item
                description={item.attributes.title}
                height={item.attributes.file.data.attributes.height}
                original={item.attributes.file.data.attributes.url}
                thumbnail={image}
                width={item.attributes.file.data.attributes.width}
              >
                {({ ref, open }) => (
                  <Card
                    elevation={4}
                    sx={{ m: 1 }}
                  >
                    <CardActionArea
                      ref={ref}
                      onClick={open}
                      target="_blank"
                    >
                      {isPreviewAllowed(item.attributes.file) ? (
                        <CardMedia
                          component="img"
                          height={200}
                          src={image}
                          sx={{
                            objectFit: 'contain',
                          }}
                        />
                      ) : (
                        <Box
                          sx={{
                            height: '200px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            backgroundColor: 'divider',
                          }}
                        >
                          <FiFile
                            style={{
                              width: 100,
                              height: 100,
                              color: theme.palette.text.secondary,
                            }}
                          />
                        </Box>
                      )}
                      <CardContent sx={{ p: 1 }}>
                        <Typography component="div" variant="caption">
                          {item.attributes.title || '-'}
                        </Typography>
                      </CardContent>
                      <div />
                    </CardActionArea>
                  </Card>
                )}
              </Item>
            </swiper-slide>
          );
        })}
      </swiper-container>
      <style global jsx>
        {`
          swiper-container::part(container) {
            padding-bottom: 20px;
          }

          swiper-container::part(pagination) {
            bottom: 0 !important;
          }

          swiper-container::part(bullet-active) {
            background: ${theme.palette.primary.main} !important;
          }

          .pswp__dynamic-caption {
            padding-right: 5px;
            padding-bottom: 30px;
            overflow-y: auto;
            max-height: 50vh;
            font-size: 0.9rem;
            white-space: pre-wrap;
          }
        `}
      </style>
    </Gallery>
  );
}

function renderCaption(slide) {
  return slide.data.description;
}

const ALLOW_IMAGE_PREVIEW = [
  '.png',
  '.jpg',
  '.jpeg',
  '.gif',
  '.svg',
];

function isPreviewAllowed(file) {
  const fileName = file.data.attributes.name;
  const extension = `.${fileName.split('.').pop().toLowerCase()}`;

  return ALLOW_IMAGE_PREVIEW.includes(extension);
}
