/* eslint-disable react/no-array-index-key */
import { Fragment } from 'react';
import { Typography } from '@mui/material';

export default function TagLeave(props) {
  const { name, leaveTypographyProps } = props;

  return (name ?? '').split(' ').map((word, index) => (
    <Fragment key={index}>
      <Typography
        {...leaveTypographyProps}
        sx={{ whiteSpace: 'nowrap' }}
        variant={leaveTypographyProps?.variant ?? 'body2'}
      >
        {word}
      </Typography>
      <Typography variant={leaveTypographyProps?.variant ?? 'body2'}>
        &nbsp;
      </Typography>
    </Fragment>
  ));
}
