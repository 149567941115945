import {
  useCallback,
  useEffect,
} from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Skeleton,
  Tooltip,
} from '@mui/material';
import {
  useDispatch,
  useSelector,
} from '@store';
import {
  selectCurrentProject,
  setProject,
} from '@modules/projects/state/slices/project';
import { gql } from '@apollo/client';
import { rootPath } from '@modules/projects/module';
import { useLazyQuery } from '@lib/hooks';
import { FiX } from 'react-icons/fi';
import LoadingSkeleton from '@modules/projects/components/project-details/LoadingSkeleton';
import MainContent from '@modules/projects/components/project-details/MainContent';
import { DEFAULT_VIEW } from '@modules/projects/config';
import trim from 'lodash/trim';
import Head from 'next/head';
import { useExternalChangeRequests } from '@modules/projects/hooks';
import { useRouter } from 'next/router';
import { getGeneralProjectInfosSchema } from '@modules/projects/data';
import { useTranslation } from 'react-i18next';
import { selectCurrentTagRoot } from '@modules/projects/state/slices/project-tag-root';
import { getGraphQlSchemaForTags } from '@modules/projects/data/tags';
import { trackPageView } from '@lib/tracking';

export default function ProjectDetailsPage(props) {
  const {
    onOpen,
    onClose,
  } = props;

  const { tag } = useSelector(selectCurrentTagRoot);
  const router = useRouter();
  const { t, i18n } = useTranslation();
  const { id, showChangeRequests } = useSelector(selectCurrentProject);
  const dispatch = useDispatch();
  const [fetchProject, { data, loading }] = useLazyQuery(FETCH);
  const [applyChangeRequests] = useExternalChangeRequests(id, showChangeRequests);

  useEffect(() => {
    const handleRouteChange = (/* url, { shallow } */) => dispatch(setProject(null));
    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router, dispatch]);
  useEffect(() => {
    if (id) {
      fetchProject({
        variables: {
          id,
          locale: i18n.language,
          tag,
        },
      })
        .then(() => {
          trackPageView(window.location.href.split('?')[0]);
        })
        .catch(console.error);
    }
  }, [fetchProject, id, i18n.language, tag]);
  useEffect(() => {
    if (id && onOpen) {
      onOpen(id);
    }
  }, [onOpen, id]);

  const handleClose = useCallback(() => {
    dispatch(setProject(null));

    if (!window.location.href.includes('/member')) {
      restoreUrl();
    }

    if (onClose) {
      onClose();
    }
  }, [dispatch, onClose]);

  let project = data?.project.data;
  const project2 = data?.calculatedProject;
  project = applyChangeRequests(project);
  const tagItems = data?.tagItems.data ?? [];

  return (
    <>
      {Boolean(id) && (
        <Head>
          <title>
            {loading || !project2 ? '...' : project2.name}
          </title>
          <meta content={project2?.name} property="og:title" />
          {project2?.logos.slice(0, 1).map((logo) => (
            <meta key={logo.url} content={logo.url} property="og:image" />
          ))}
        </Head>
      )}
      <Dialog
        fullWidth
        maxWidth="xl"
        onClose={handleClose}
        open={Boolean(id)}
        scroll="paper"
      >
        <DialogTitle>
          {loading || !project ? (
            <Skeleton sx={{ maxWidth: 300 }} />
          ) : t('modules.projects.components.projectDetails.ProjectDetailsPage.Dialog.title')}
        </DialogTitle>
        <Tooltip placement="left" title={t('modules.projects.components.projectDetails.ProjectDetailsPage.Dialog.closeProject')}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <FiX />
          </IconButton>
        </Tooltip>
        <DialogContent sx={{ py: 0 }}>
          <Paper
            sx={{
              pb: 2,
              backgroundColor: 'background.paper',
              border: 'none',
            }}
            variant="outlined"
          >
            {loading || !project
              ? <LoadingSkeleton />
              : (
                <MainContent
                  project={project}
                  project2={project2}
                  t={t}
                  tagItems={tagItems}
                />
              )}
          </Paper>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>
            {t('modules.projects.components.projectDetails.ProjectDetailsPage.Dialog.closeProject')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export function useProjectDetailsPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    const url = new URL(window.location.href);
    const projectId = url.searchParams.get('id');

    if (projectId) {
      dispatch(setProject({ id: projectId }));
      applyUrl(projectId, 'list');
    }
  }, [dispatch]);

  return {
    openProjectDetailsPage: (id, view, showChangeRequests = false) => {
      dispatch(setProject({ id, showChangeRequests }));

      if (view) {
        applyUrl(id, view);
      }
    },
  };
}

function applyUrl(id, view) {
  const url = new URL(window.location.href);
  url.searchParams.delete('id');
  url.searchParams.set('view', view);
  let newUrl = `${rootPath}/${id}`;
  newUrl = trim(`${newUrl}?${url.searchParams.toString()}`, ['?', '&']);
  window.history.pushState(null, '', newUrl);
}

function restoreUrl() {
  const url = new URL(window.location.href);
  const view = url.searchParams.get('view') || DEFAULT_VIEW;
  url.searchParams.delete('view');
  let newUrl = `${rootPath}/${view}`;
  newUrl = trim(`${newUrl}?${url.searchParams.toString()}`, ['?', '&']);
  window.history.pushState(null, '', newUrl);
}

const FETCH = gql`
  query Fetch($id: ID!, $locale: String!, $tag: String!) {
    calculatedProject(id: $id, locale: $locale)
    tagItems(
      sort: ["index", "name_de"],
      filters: {
        referenceId: {eq: $tag}
      }
    ) {
      data {
        ${getGraphQlSchemaForTags()}
      }
    }
    project(id: $id) {
      data {
        id
        attributes {
          ${getGeneralProjectInfosSchema({
    projectDescriptions: true,
    projectLogo: true,
    projectLinks: true,
    projectFiles: true,
    organizationTags: true,
    fundingOrganization: true,
    executingOrganizations: true,
    finalBeneficiaryOrganization: true,
    projectSponsorOrganization: true,
    projectManagerOrganization: true,
    projectManagerContact: true,
    alternativeContact: true,
    fullSubProjects: true,
    projectAttributes: `
      name_de
      referenceId
      abbreviation
      projectType
      projectStart
      projectEnd
      fundingAmount
      fundingProfile
      fundingType
      projectVolume
      projectVolumeOverride
      performancePlanSystem
      website
      isShowNotesForSubProjects
      funding_measure {
        data {
          id
          attributes {
            name_de
            tag_item_abbreviations(
              sort: ["index"],
              pagination: { limit: 1 }
            ) {
              data {
                id
                attributes {
                  name_de
                }
              }
            }
            tag_item_links(
              sort: ["index"],
              pagination: { limit: 100 }
            ) {
              data {
                id
                attributes {
                  name_de
                  url
                }
              }
            }
            tag_item_files(
              sort: ["index"],
              pagination: { limit: 100 }
            ) {
              data {
                id
                attributes {
                  isPublic
                  type
                  title
                  file {
                    data {
                      id
                      attributes {
                        url
                        formats
                        name
                        size
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `,
    organizationAttributes: `
      organizationType
      publicationState
      liquidationState
      name_de
      name_en
      abbreviation_de
      abbreviation_en
      addressStreet
      addressStreetNumber
      addressZipCode
      addressCity
      liquidationDate
      isHistoryVisible
      isParentOrgHidden
      isParentOrgLogoHidden
      website_de
      website_en
      organization_histories(
        pagination: { limit: 100 }
      ) {
        data {
          id
          attributes {
            date
            abbreviation_de
            abbreviation_en
          }
        }
      }
      logo_default_de {
        data {
          id
          attributes {
            url
            formats
            name
            size
          }
        }
      }
      logo_funding_partner_de {
        data {
          id
          attributes {
            url
            formats
            name
            size
          }
        }
      }
    `,
  })}
        }
      }
    }
  }
`;
