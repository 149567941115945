import {
  Box,
  Paper,
  Tab,
  Tabs,
} from '@mui/material';
import {
  useEffect,
  useMemo,
  useState,
} from 'react';
import ProjectDescription from '@modules/projects/components/project-details/views/ProjectDescription';
import ProjectContact from '@modules/projects/components/project-details/views/ProjectContact';
import ProjectAdditionalInformation from '@modules/projects/components/project-details/views/ProjectAdditionalInformation';
import ProjectInformation from '@modules/projects/components/project-details/views/ProjectInformation';
import ProjectTags from '@modules/projects/components/project-details/views/ProjectTags';
import ProjectHead from './ProjectHead';

export default function MainContent(props) {
  const {
    t,
    project,
    project2,
    tagItems,
  } = props;

  const visibleTabs = useMemo(
    () => TABS.filter((tab) => !tab.filter || tab.filter(project, project2)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [project?.id],
  );
  const [view, setView] = useState(visibleTabs[0].id);
  const Component = TABS.find((tab) => tab.id === view).component;

  useEffect(() => {
    setView(visibleTabs[0].id);
  }, [visibleTabs]);

  return (
    <>
      <ProjectHead project={project2} tagItems={tagItems} />
      <Paper sx={{ mt: 3 }} variant="outlined">
        <Tabs
          indicatorColor="primary"
          onChange={(_, value) => setView(value)}
          scrollButtons="auto"
          sx={{
            px: 2,
            borderBottom: 1,
            borderColor: 'divider',
            bgcolor: 'background.default',
            borderTopLeftRadius: (theme) => theme.shape.borderRadius,
            borderTopRightRadius: (theme) => theme.shape.borderRadius,
          }}
          textColor="primary"
          value={view}
          variant="scrollable"
        >
          {visibleTabs.map((tab) => (
            <Tab
              key={tab.id}
              label={t(`modules.projects.components.projectDetails.MainContent.tabs.${tab.id}`)}
              value={tab.id}
            />
          ))}
        </Tabs>
        <Box p={2}>
          <Component project={project} project2={project2} t={t} />
        </Box>
      </Paper>
    </>
  );
}

const TABS = [
  {
    id: 'description',
    label: 'Beschreibung',
    component: ProjectDescription,
    filter: ProjectDescription.tabFilter,
  },
  {
    id: 'tags',
    label: 'Schlagworte',
    component: ProjectTags,
    filter: ProjectTags.tabFilter,
  },
  {
    id: 'details',
    label: 'Projektdaten',
    component: ProjectInformation,
    filter: ProjectInformation.tabFilter,
  },
  {
    id: 'contact',
    label: 'Kontakt',
    component: ProjectContact,
    filter: ProjectContact.tabFilter,
  },
  {
    id: 'more',
    label: 'Weitere Infos',
    component: ProjectAdditionalInformation,
    filter: ProjectAdditionalInformation.tabFilter,
  },
];
