import {
  Alert,
  Box,
  Divider,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import Swiper from '@components/Swiper';
import MarkdownText from '@components/MarkdownText';

export default function ProjectDescription({ t, project }) {
  const theme = useTheme();
  const files = filterFiles(project.attributes.project_files?.data);
  const style = {
    textAlign: 'left',
    columns: 1,
    [theme.breakpoints.up('md')]: {
      columns: 2,
    },
    [theme.breakpoints.up('lg')]: {
      columns: 2,
    },
    '& p': {
      marginTop: 0,
      marginBottom: '1rem',
    },
  };

  return (
    <>
      <Typography gutterBottom variant="h5">
        {t(`modules.projects.components.projectDetails.views.ProjectDescription.${project.attributes.projectType}`)}
      </Typography>
      {project.attributes.isShowNotesForSubProjects && (
        <Alert severity="info" sx={{ mb: 3 }} variant="filled">
          <strong>{t('modules.projects.components.projectDetails.views.ProjectDescription.Alert.title')}</strong>
          {' '}
          {t('modules.projects.components.projectDetails.views.ProjectDescription.Alert.content')}
        </Alert>
      )}
      {project.attributes.project_short_descriptions?.data?.length > 0 && (
        <Box mb={5}>
          <Typography gutterBottom variant="h6">
            {t('modules.projects.components.projectDetails.views.ProjectDescription.briefDescription')}
          </Typography>
          <ul style={{ margin: 0 }}>
            {project.attributes.project_short_descriptions.data.map((item) => (
              <li key={item.id}>
                <Typography variant="body2">
                  {item.attributes.name_de}
                </Typography>
              </li>
            ))}
          </ul>
        </Box>
      )}
      {(project.attributes.description1
        || project.attributes.description2
        || project.attributes.description3) && (
        <Typography gutterBottom variant="h6">
          {t('modules.projects.components.projectDetails.views.ProjectDescription.detailedDescription')}
        </Typography>
      )}
      {project.attributes.description1 && (
        <Box mb={5}>
          <Title>
            {t('modules.projects.components.projectDetails.views.ProjectDescription.challengesGoals')}
          </Title>
          <MarkdownText
            sx={style}
            value={project.attributes.description1}
            variant="body2"
          />
        </Box>
      )}
      {project.attributes.description2 && (
        <Box mb={5}>
          <Title>
            {t('modules.projects.components.projectDetails.views.ProjectDescription.contentFocusOfWork')}
          </Title>
          <MarkdownText
            sx={style}
            value={project.attributes.description2}
            variant="body2"
          />
        </Box>
      )}
      {project.attributes.description3 && (
        <Box mb={5}>
          <Title>
            {t('modules.projects.components.projectDetails.views.ProjectDescription.utilizationOfTheResults')}
          </Title>
          <MarkdownText
            sx={style}
            value={project.attributes.description3}
            variant="body2"
          />
        </Box>
      )}
      {files.length > 0 && (
        <Box sx={{
          backgroundColor: 'background.default',
          ml: -2,
          mr: -2,
          mb: -2,
        }}
        >
          <Divider />
          <Box sx={{ p: 2 }}>
            <Swiper items={files} />
          </Box>
        </Box>
      )}
    </>
  );
}

ProjectDescription.tabFilter = (project) => (
  project.attributes.project_short_descriptions?.data?.length > 0
  || project.attributes.description1
  || project.attributes.description2
  || project.attributes.description3
  || filterFiles(project.attributes.project_files?.data).length > 0
);

function Title({ children }) {
  return (
    <Typography sx={{ mb: 1, fontWeight: 'bold' }} variant="subtitle2">
      {children}
    </Typography>
  );
}

const title = {
  individualProject: 'Inhaltliche Beschreibung des Projektes',
  subProject: 'Inhaltliche Beschreibung des Teilprojektes',
  jointProject: 'Inhaltliche Beschreibung des Verbundprojektes',
};

function filterFiles(files) {
  if (!files) return [];

  return files.filter((file) => [
    'logo',
    'photo',
  ].includes(file.attributes.type));
}
