import {
  Box,
  Grid,
  Skeleton,
} from '@mui/material';
import React from 'react';

export default function LoadingSkeleton() {
  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item md={8} sm={7} xs={12}>
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </Grid>
        <Grid item md={4} sm={5} xs={12}>
          <Skeleton sx={{ height: 200, mb: 2 }} variant="reactangle" />
          <Box>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </Box>
        </Grid>
      </Grid>
      <Box mt={3}>
        <Skeleton sx={{ height: 300 }} variant="reactangle" />
      </Box>
    </Box>
  );
}
