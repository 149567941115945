import { marked } from 'marked';
import {
  Typography,
  useTheme,
} from '@mui/material';
import { useMemo } from 'react';

export default function MarkdownText(props) {
  const { value, ...otherProps } = props;
  const theme = useTheme();

  const html = useMemo(() => optimizeHtml(marked(optimizeText(value ?? ''), {
    smartypants: true,
    breaks: true,
  })), [value]);

  return (
    <>
      <Typography
        {...otherProps}
        component="div"
        dangerouslySetInnerHTML={{
          __html: html,
        }}
      />
      <style global jsx>
        {`
          .markdown-link {
            color: ${theme.palette.primary.main};
          }
          .markdown-link:hover,
          .markdown-link:active,
          .markdown-link:focus {
            color: ${theme.palette.primary.dark};
          }
        `}
      </style>
    </>
  );
}

function optimizeText(value) {
  if (!value) return value;

  return value.trim().replace(/\n{1,}/g, '\n\n');
}

function optimizeHtml(value) {
  return value;
}

const renderer = {
  link(href, title, text) {
    return `<a target="_blank" class="markdown-link" href="${href}" title="${title}">${text}</a>`;
  },
};

marked.use({ renderer });
