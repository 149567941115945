import { useTranslation } from 'react-i18next';
import {
  Box,
  Typography,
} from '@mui/material';

export default function OrganizationChain(props) {
  const { chain } = props;
  const { t, i18n } = useTranslation();

  return (
    <>
      {chain.map((item, index) => (
        <Box key={item.map((o) => o.id).join('-')} mb={index > 0 ? 1 : 0} mr={2}>
          {item.map((org, index2) => (
            <Typography key={org.id} sx={{ ml: index2 }} variant="subtitle2">
              {org.attributes[`name_${i18n.language}`]}
            </Typography>
          ))}
        </Box>
      ))}
    </>
  );
}
