import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import TagRoot from './TagRoot';
import { buildTree } from './helper';

export default function TagMap(props) {
  const {
    tagItems,
    entityTags,
    hideTagRootTitle,
    renderColors,
    titleContainerProps,
    titleTypographyProps,
    leaveTypographyProps,
  } = props;
  const { i18n } = useTranslation();

  const tree = useMemo(() => buildTree(tagItems ?? [], entityTags, i18n), [
    i18n, tagItems, entityTags,
  ]);

  return tree.map((tag, index) => (
    <TagRoot
      key={tag.id}
      hideTagRootTitle={hideTagRootTitle}
      id={tag.id}
      items={tag.children}
      leaveTypographyProps={leaveTypographyProps}
      name={tag.name}
      renderColors={renderColors}
      rootCount={tree.length}
      rootIndex={index}
      titleContainerProps={titleContainerProps}
      titleTypographyProps={titleTypographyProps}
    />
  ));
}
