import { Box } from '@mui/material';
import { FiChevronRight } from 'react-icons/fi';

export default function Seperator() {
  return (
    <Box mx={0.5}>
      <FiChevronRight
        style={{ display: 'block' }}
      />
    </Box>
  );
}
