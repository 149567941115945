/* eslint-disable no-nested-ternary,react/no-array-index-key */
import {
  Box,
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { renderDateRange } from '@utils/date-utils';
import { useTranslation } from 'react-i18next';
import { FiExternalLink } from 'react-icons/fi';
import { renderMoneyValue } from '@utils';
import { getGeneralProjectInfos } from '@modules/projects/data';
import { useProjectDetailsPage } from '@modules/projects/components/project-details/ProjectDetailPage';
import {
  Fragment,
  useMemo,
} from 'react';
import ResponsiveImage from '@components/ResponsiveImage';
import TagMap from '@components/tags/TagMap';
import { useSelector } from '@store';
import { selectCurrentOrganizationRole } from '@modules/projects/state/slices/project-organization-role';

export default function ProjectHead(props) {
  const {
    project,
    tagItems,
  } = props;
  const { t, i18n } = useTranslation();
  const { role } = useSelector(selectCurrentOrganizationRole);
  const { openProjectDetailsPage } = useProjectDetailsPage();

  const cellStyle = {
    verticalAlign: 'top',
    p: 0,
    whiteSpace: 'nowrap',
    border: 'none',
  };
  const cellValueStyle = {
    p: 0,
    border: 'none',
  };

  const handleOpenProject = (id) => () => {
    openProjectDetailsPage(id, 'list');
  };

  return (
    <Grid container justifyContent="space-between" spacing={3}>
      <Grid item md={8} xs={12}>
        <Box alignItems="center" display="flex" mb={1}>
          <Typography component="h2" variant="h6">
            {project.title}
          </Typography>
          {project.isSubProject && (
            <Button
              onClick={handleOpenProject(project.jointProjectId)}
              size="small"
              startIcon={<FiExternalLink />}
              sx={{ ml: 3 }}
            >
              Verbundprojekt öffnen
            </Button>
          )}
        </Box>
        <Typography component="h1" gutterBottom variant="body2">
          {project.name}
        </Typography>
        <Typography sx={{ fontWeight: 'bold' }} variant="subtitle2">
          {t(`enums.projectType.${project.type.value}`)}
          {' '}
          {project.type.label1}
          {project.type.label2 && (
            <Typography
              color="textSecondary"
              component="span"
              sx={{ fontWeight: 'bold' }}
              variant="subtitle2"
            >
              {project.type.label2}
            </Typography>
          )}
        </Typography>
        <Box mt={1}>
          <TagMap
            entityTags={project.tags}
            hideTagRootTitle
            leaveTypographyProps={{ variant: 'caption' }}
            renderColors
            tagItems={tagItems}
          />
        </Box>
      </Grid>
      <Grid item md="auto" xs={12}>
        {project.logos.map((logo) => (
          <ResponsiveImage
            key={logo.url}
            mb={2}
            src={logo.url}
          />
        ))}
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell
                sx={{
                  ...cellStyle, width: '1px', pr: 1, pb: 1,
                }}
              >
                <Typography component="div" variant="caption">
                  {t(`modules.projects.components.CompactProjectItem.${project.isShowNotesForSubProjects ? 'durationSubProjects' : 'runTime'}`)}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  ...cellValueStyle, pb: 1,
                }}
              >
                <Typography component="div" sx={{ fontWeight: 'bold' }} variant="caption">
                  {renderDateRange(
                    t,
                    i18n,
                    project.projectStart,
                    project.projectEnd,
                  )}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ ...cellStyle, width: '1px', pr: 1 }}>
                <Typography component="div" variant="caption">
                  {t(`modules.projects.components.CompactProjectItem.${project.type.value === 'jointProject' ? 'projectCoordination' : role}`)}
                </Typography>
              </TableCell>
              <TableCell sx={cellValueStyle}>
                <Typography component="div" sx={{ fontWeight: 'bold' }} variant="caption">
                  {project.type.value === 'jointProject' ? (
                    project.projectManagerOrg.slice(0, 1).map((chain, index) => (
                      <Box key={`${index}_${chain.length}`}>
                        {chain.map((org, index2) => (
                          <Fragment key={org}>
                            <Typography component="span" sx={{ fontWeight: 'bold' }} variant="caption">
                              {org}
                            </Typography>
                            {index2 < chain.length - 1 && <span>&nbsp;&bull;&nbsp;</span>}
                          </Fragment>
                        ))}
                      </Box>
                    ))
                  ) : (
                    <>
                      {role === 'primary_executing_org' && (
                        project.projectExecutingOrgs.slice(0, 1).map((chain, index) => (
                          <Box key={`${index}_${chain.length}`}>
                            {chain.map((org, index2) => (
                              <Fragment key={org}>
                                <Typography component="span" sx={{ fontWeight: 'bold' }} variant="caption">
                                  {org}
                                </Typography>
                                {index2 < chain.length - 1 && <span>&nbsp;&bull;&nbsp;</span>}
                              </Fragment>
                            ))}
                          </Box>
                        ))
                      )}
                      {role === 'final_beneficiary_org' && (
                        project.projectFinalBeneficiaryOrgs.map((org, index2) => (
                          <Fragment key={org}>
                            <Typography component="span" sx={{ fontWeight: 'bold' }} variant="caption">
                              {org}
                            </Typography>
                            {index2 < project.projectFinalBeneficiaryOrgs.length - 1
                              && <span>&nbsp;&bull;&nbsp;</span>}
                          </Fragment>
                        ))
                      )}
                    </>
                  )}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  ...cellStyle, width: '1px', pr: 1, pb: 1,
                }}
              >
                <Typography component="div" variant="caption">
                  {t('modules.projects.components.CompactProjectItem.location')}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  ...cellValueStyle, pb: 1,
                }}
              >
                <Typography component="div" sx={{ fontWeight: 'bold' }} variant="caption">
                  {`${project.projectLocation.addressZipCod ?? ''} ${project.projectLocation.addressCity ?? ''}`.trim() || 'k. A.'}
                </Typography>
              </TableCell>
            </TableRow>
            {project.type.value === 'jointProject' && (
              <TableRow>
                <TableCell
                  sx={{
                    ...cellStyle, width: '1px', pr: 1, pb: 1,
                  }}
                >
                  <Typography component="div" variant="caption">
                    {t('modules.projects.components.CompactProjectItem.projectParticipants')}
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    ...cellValueStyle, pb: 1,
                  }}
                >
                  <Typography component="div" sx={{ fontWeight: 'bold' }} variant="caption">
                    {project.projectExecutingOrgs.map((chain, index) => (
                      <Box key={`${index}_${chain.length}`}>
                        {chain.map((org, index2) => (
                          <Fragment key={org}>
                            {org}
                            {index2 < chain.length - 1 && <span>&nbsp;&bull;&nbsp;</span>}
                          </Fragment>
                        ))}
                      </Box>
                    ))}
                  </Typography>
                </TableCell>
              </TableRow>
            )}
            <TableRow>
              <TableCell sx={{ ...cellStyle, width: '1px', pr: 1 }}>
                <Typography component="div" variant="caption">
                  {t(`modules.projects.components.CompactProjectItem.${project.isShowNotesForSubProjects ? 'fundingAmountSubProjects' : 'fundingAmount'}`)}
                </Typography>
              </TableCell>
              <TableCell sx={cellValueStyle}>
                <Typography component="div" sx={{ fontWeight: 'bold' }} variant="caption">
                  {renderMoneyValue(project.fundingAmount) ?? 'k. A.'}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  ...cellStyle, width: '1px', pr: 1, pb: 1,
                }}
              >
                <Typography component="div" variant="caption">
                  {t(`modules.projects.components.CompactProjectItem.${project.isShowNotesForSubProjects ? 'projectSubProjects' : 'projectVolume'}`)}
                </Typography>
              </TableCell>
              <TableCell
                sx={{
                  ...cellValueStyle, pb: 1,
                }}
              >
                <Typography component="div" sx={{ fontWeight: 'bold' }} variant="caption">
                  {renderMoneyValue(project.projectVolume) ?? 'k. A.'}
                </Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell sx={{ ...cellStyle, width: '1px', pr: 1 }}>
                <Typography component="div" variant="caption">
                  {t('modules.projects.components.projectDetails.ProjectHead.sponsor')}
                </Typography>
              </TableCell>
              <TableCell sx={cellValueStyle}>
                <Typography component="div" sx={{ fontWeight: 'bold' }} variant="caption">
                  {project.projectFundingOrgs}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Grid>
    </Grid>
  );
}
