export function getGraphQlSchemaForTags(depth = 0) {
  if (depth >= 25) {
    return `
      id
      attributes {
        ${TAG_ITEM_ATTRIBUTES}
      }
    `;
  }

  return `
    id
    attributes {
      ${TAG_ITEM_ATTRIBUTES}
      child_items(
        sort: ["index", "name_de", "id"],
        pagination: { limit: 100 }
      ) {
        data {
          ${getGraphQlSchemaForTags(depth + 1)}
        }
      }
    }
  `;
}

const TAG_ITEM_ATTRIBUTES = `
name_de
name_en
color
index
referenceId
isAbbreviationUsedAsLabel
tag_item_abbreviations(
  sort: ["index"],
  pagination: {limit: 1}
) {
  data {
    id
    attributes {
      name_de
      name_en
    }
  }
}
`;
