export function parseUserRoleMetadata(role) {
  return ({
    ...role,
    metadata: parseMetadata(role.attributes.description),
  });
}

function parseMetadata(data) {
  try {
    return JSON.parse(data);
  } catch {
    return {};
  }
}
