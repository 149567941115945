import { DateTime } from 'luxon';
import {
  FILTER_ORGANIZATION_ROLE,
  FILTER_PROJECT_TYPES,
} from '@modules/projects/config';
import merge from 'lodash/merge';

export function useProjectQueryFilters(search, { filter, defaultFilters }) {
  const orgRole = FILTER_ORGANIZATION_ROLE
    .find((f) => f.id === filter.organizationRoleFilter)?.value
    ?? FILTER_ORGANIZATION_ROLE[0].value;
  let queryFilter = {
    isDeleted: { eq: false },
    publicationState: { ne: 'draft' },
  };

  const { projectFilters } = filter;

  if (filter.projectTypeFilter) {
    queryFilter.projectType = {
      in: FILTER_PROJECT_TYPES.find((o) => o.id === filter.projectTypeFilter)?.value,
    };
  }

  if (projectFilters?.addressCity) {
    queryFilter.and = queryFilter.and || [];
    queryFilter.and.push({
      or: [
        {
          projectType: { eq: 'jointProject' },
          project_manager_org: { addressCity: { containsi: projectFilters.addressCity } },
        },
        {
          projectType: { in: ['individualProject', 'subProject'] },
          [orgRole]: { addressCity: { containsi: projectFilters.addressCity } },
        },
      ],
    });
  }

  if (projectFilters?.addressState) {
    queryFilter.and = queryFilter.and || [];
    queryFilter.and.push({
      or: [
        {
          projectType: { eq: 'jointProject' },
          project_manager_org: { addressState: { containsi: projectFilters.addressState } },
        },
        {
          projectType: { in: ['individualProject', 'subProject'] },
          [orgRole]: { addressState: { containsi: projectFilters.addressState } },
        },
      ],
    });
  }

  if (projectFilters?.final_beneficiary_org) {
    queryFilter.and = queryFilter.and || [];
    queryFilter.and.push({
      final_beneficiary_org: {
        id: { in: [projectFilters.final_beneficiary_org] },
      },
    });
  }

  if (projectFilters?.executing_org) {
    queryFilter.and = queryFilter.and || [];
    queryFilter.and.push({
      primary_executing_org: {
        id: { in: [projectFilters.executing_org] },
      },
    });
  }

  if (projectFilters?.funding_org) {
    queryFilter.and = queryFilter.and || [];
    queryFilter.and.push({
      funding_org: {
        or: [
          {
            id: { eq: projectFilters.funding_org },
          },
          {
            parent_organizations: populateFundingOrgFilter(projectFilters.funding_org),
          },
        ],
      },
    });
  }

  if (projectFilters.fundingMeasure) {
    queryFilter.funding_measure = {
      id: { eq: projectFilters.fundingMeasure },
    };
  }

  if (projectFilters?.tags) {
    projectFilters.tags.forEach((tag) => {
      if (tag.fullTreeFromSelection?.length > 0) {
        queryFilter.and = queryFilter.and || [];
        queryFilter.and.push({
          or: [
            {
              tag_items: {
                tag_item: {
                  id: {
                    in: tag.fullTreeFromSelection,
                  },
                },
              },
            },
            {
              parent_project: {
                tag_items: {
                  tag_item: {
                    id: {
                      in: tag.fullTreeFromSelection,
                    },
                  },
                },
              },
            },
            {
              projects: {
                tag_items: {
                  tag_item: {
                    id: {
                      in: tag.fullTreeFromSelection,
                    },
                  },
                },
              },
            },
          ],
        });
      }
    });
  }

  if (projectFilters?.projectPeriod) {
    queryFilter.and = queryFilter.and || [];
    queryFilter.or = queryFilter.or || [];

    switch (projectFilters?.projectPeriod) {
      case 'current':
        if (!projectFilters.projectStart && !projectFilters.projectEnd) {
          queryFilter.or.push({
            and: [
              { projectStart: { lte: DateTime.now().toISODate() } },
              { projectEnd: { null: true } },
            ],
          });
          queryFilter.or.push({
            and: [
              { projectStart: { lte: DateTime.now().toISODate() } },
              { projectEnd: { gte: DateTime.now().toISODate() } },
            ],
          });
        } else if (projectFilters.projectStart && !projectFilters.projectEnd) {
          queryFilter.or.push({
            and: [
              { projectStart: { lte: DateTime.now().toISODate() } },
              { projectEnd: { null: true } },
            ],
          });
          queryFilter.or.push({
            and: [
              { projectStart: { lte: DateTime.now().toISODate() } },
              { projectEnd: { gte: DateTime.fromISO(projectFilters.projectStart).toISODate() } },
            ],
          });
        } else if (!projectFilters.projectStart && projectFilters.projectEnd) {
          queryFilter.or.push({
            and: [
              { projectStart: { lte: DateTime.fromISO(projectFilters.projectEnd).toISODate() } },
              { projectEnd: { null: true } },
            ],
          });
          queryFilter.or.push({
            and: [
              { projectStart: { lte: DateTime.fromISO(projectFilters.projectEnd).toISODate() } },
              { projectEnd: { gte: DateTime.now().minus({ years: 100 }).toISODate() } },
            ],
          });
        } else {
          queryFilter.or.push({
            and: [
              { projectStart: { lte: DateTime.fromISO(projectFilters.projectEnd).toISODate() } },
              { projectEnd: { null: true } },
            ],
          });
          queryFilter.or.push({
            and: [
              { projectStart: { lte: DateTime.fromISO(projectFilters.projectEnd).toISODate() } },
              { projectEnd: { gte: DateTime.fromISO(projectFilters.projectStart).toISODate() } },
            ],
          });
        }
        break;
      case 'started':
        if (!projectFilters.projectStart && !projectFilters.projectEnd) {
          queryFilter.or.push({
            and: [
              { projectStart: { lte: DateTime.now().toISODate() } },
              { projectEnd: { null: true } },
            ],
          });
          queryFilter.or.push({
            and: [
              { projectStart: { lte: DateTime.now().toISODate() } },
              { projectEnd: { gte: DateTime.now().toISODate() } },
            ],
          });
        } else if (projectFilters.projectStart && !projectFilters.projectEnd) {
          queryFilter.and.push(
            { projectStart: { gte: DateTime.fromISO(projectFilters.projectStart).toISODate() } },
            { projectStart: { lte: DateTime.now().toISODate() } },
          );
        } else if (!projectFilters.projectStart && projectFilters.projectEnd) {
          queryFilter.and.push(
            { projectStart: { gte: DateTime.now().minus({ years: 100 }).toISODate() } },
            { projectStart: { lte: DateTime.fromISO(projectFilters.projectEnd).toISODate() } },
          );
        } else {
          queryFilter.and.push(
            { projectStart: { gte: DateTime.fromISO(projectFilters.projectStart).toISODate() } },
            { projectStart: { lte: DateTime.fromISO(projectFilters.projectEnd).toISODate() } },
          );
        }
        break;
      case 'expired':
        if (!projectFilters.projectStart && !projectFilters.projectEnd) {
          queryFilter.and.push(
            { projectEnd: { gte: DateTime.now().minus({ years: 100 }).toISODate() } },
            { projectEnd: { lte: DateTime.now().toISODate() } },
          );
        } else if (projectFilters.projectStart && !projectFilters.projectEnd) {
          queryFilter.and.push(
            { projectEnd: { gte: DateTime.fromISO(projectFilters.projectStart).toISODate() } },
            { projectEnd: { lte: DateTime.now().toISODate() } },
          );
        } else if (!projectFilters.projectStart && projectFilters.projectEnd) {
          queryFilter.and.push(
            { projectEnd: { gte: DateTime.now().minus({ years: 100 }).toISODate() } },
            { projectEnd: { lte: DateTime.fromISO(projectFilters.projectEnd).toISODate() } },
          );
        } else {
          queryFilter.and.push(
            { projectEnd: { gte: DateTime.fromISO(projectFilters.projectStart).toISODate() } },
            { projectEnd: { lte: DateTime.fromISO(projectFilters.projectEnd).toISODate() } },
          );
        }
        break;
      default:
        break;
    }
  }

  if (projectFilters?.fundingAmount?.length === 2) {
    queryFilter.fundingAmount = {
      gte: projectFilters.fundingAmount[0],
      lte: projectFilters.fundingAmount[1],
    };
  }

  if (search) {
    queryFilter.and = queryFilter.and || [];
    queryFilter.and.push({
      or: [
        { name_de: { containsi: search } },
        { referenceId: { containsi: search } },
        { abbreviation: { containsi: search } },
        // Parent project
        { parent_project: { name_de: { containsi: search } } },
        { parent_project: { abbreviation: { containsi: search } } },
        // Sub projects
        { projects: { name_de: { containsi: search } } },
        { projects: { abbreviation: { containsi: search } } },
        // primary_executing_org
        { primary_executing_org: { or: populateSearchOrFilter(search) } },
        // project_manager_org
        { project_manager_org: { or: populateSearchOrFilter(search) } },
      ],
    });
  }

  // ! Do not remove this merging here !
  // Necessary for widgets where default filters can be set.
  if (defaultFilters) {
    queryFilter = merge(queryFilter, defaultFilters);
  }

  return queryFilter;
}

function populateSearchOrFilter(search, level = 0) {
  if (level === 10) {
    return [];
  }

  return [
    { name_de: { containsi: search } },
    { name_en: { containsi: search } },
    { abbreviation: { containsi: search } },
    { organization_histories: { name_de: { containsi: search } } },
    { organization_histories: { name_en: { containsi: search } } },
    { organization_histories: { abbreviation_de: { containsi: search } } },
    { organization_histories: { abbreviation_en: { containsi: search } } },
    {
      parent_organizations: {
        and: [
          {
            organizationType: { ne: 'press' },
          },
          {
            or: populateSearchOrFilter(search, level + 1),
          },
        ],
      },
    },
  ];
}

function populateFundingOrgFilter(id, level = 0) {
  if (level === 10) {
    return {};
  }
  return {
    or: [
      { id: { eq: id } },
      { parent_organizations: populateFundingOrgFilter(id, level + 1) },
    ],
  };
}
