/* eslint-disable react/no-array-index-key */
import { useQuery } from '@lib/hooks';
import { gql } from '@apollo/client';
import {
  Alert,
  Box,
  Skeleton,
  Typography,
} from '@mui/material';
import { PROJECT_TAG_REFERENCES } from '@modules/projects/config';
import TagMap from '@components/tags/TagMap';

export default function ProjectTags(props) {
  const {
    t,
    project2: project,
  } = props;

  const { data, loading } = useQuery(GET_DATA, {
    variables: {
      references: PROJECT_TAG_REFERENCES.map((x) => x.id),
    },
  });

  return (
    <Box>
      <Typography component="div" mb={2} variant="body2">
        {t(`modules.projects.components.projectDetails.views.ProjectTags.${project.type.value}`)}
      </Typography>
      {project.isShowNotesForSubProjects && (
        <Alert severity="info" sx={{ mb: 3 }} variant="filled">
          <strong>{t('modules.projects.components.projectDetails.views.ProjectDescription.Alert.title')}</strong>
          {' '}
          {t('modules.projects.components.projectDetails.views.ProjectDescription.Alert.content')}
        </Alert>
      )}
      {loading && [1, 2, 3].map((key) => (
        <Box key={key} mb={2}>
          <Box alignItems="center" display="flex">
            <Typography sx={{ flex: 1 }} variant="subtitle2">
              <Skeleton />
            </Typography>
          </Box>
          <Box>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </Box>
        </Box>
      ))}
      {!loading && (
        <TagMap
          entityTags={project.tags}
          tagItems={data?.tagItems.data}
        />
      )}
    </Box>
  );
}

ProjectTags.tabFilter = (project, project2) => project2.tags.length > 0;

const TAG_ITEM_ATTRIBUTES = `
name_de
name_en
index
referenceId
`;

function getGraphQlSchemaForTagItem(depth = 0) {
  if (depth >= 25) {
    return `
      id
      attributes {
        ${TAG_ITEM_ATTRIBUTES}
      }
    `;
  }

  return `
    id
    attributes {
      ${TAG_ITEM_ATTRIBUTES}
      child_items(
        sort: ["index", "name_de", "id"],
        pagination: { limit: 100 }
      ) {
        data {
          ${getGraphQlSchemaForTagItem(depth + 1)}
        }
      }
    }
  `;
}

const GET_DATA = gql`
  query TagRoot($references: [String]!) {
    tagItems(
      sort: ["index", "name_de"],
      filters: {
        referenceId: {in: $references}
      }
    ) {
      data {
        ${getGraphQlSchemaForTagItem()}
      }
    }
  }
`;
