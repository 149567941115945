import {
  Box,
  Button,
  Chip,
  Link,
  Typography,
} from '@mui/material';
import {
  FiMail,
  FiPhone,
} from 'react-icons/fi';
import { useTranslation } from 'react-i18next';

const T_NAMESPACE = 'modules.projects.components.projectDetails.fragments.Contact.salutations';

export default function Contact(props) {
  const {
    contact,
    onOpenContactForm,
  } = props;
  const { t, i18n } = useTranslation();

  const {
    isActiveContact,
    isNamePublic,
    isPhonePublic,
    isEmailPublic,
  } = contact.attributes;

  const hasEmail = isEmailPublic
    && contact.attributes.email
    && !DISALLOWED_DOMAINS.find((d) => contact.attributes.email.includes(`@${d}`));

  return (
    <Box>
      {!isActiveContact && !isNamePublic ? (
        <Chip label="Nicht mehr im Unternehmen tätig." size="small" />
      ) : (
        <Box>
          {isNamePublic && (
            <>
              {contact.attributes.salutation && (
                <Typography variant="body2">
                  {i18n.exists(`${T_NAMESPACE}.${contact.attributes.salutation}`)
                    ? t(`${T_NAMESPACE}.${contact.attributes.salutation}`)
                    : contact.attributes.salutation}
                </Typography>
              )}
              <Typography sx={{ fontWeight: 'bold' }} variant="subtitle2">
                {contact.attributes.title}
                {' '}
                {contact.attributes.firstName}
                {' '}
                {contact.attributes.lastName}
              </Typography>
              {!isActiveContact && (
                <Chip label="Nicht mehr im Unternehmen tätig." size="small" />
              )}
            </>
          )}
        </Box>
      )}
      {isPhonePublic && (
        <Box alignItems="center" display="flex">
          <FiPhone style={{ display: 'block' }} />
          <Link
            href={`tel:${contact.attributes.phone.replaceAll(' ', '')}`}
            sx={{ ml: 1 }}
            variant="body2"
          >
            {contact.attributes.phone}
          </Link>
        </Box>
      )}
      {hasEmail && (
        <>
          <Box alignItems="center" display="flex">
            <FiMail style={{ display: 'block' }} />
            <Link
              href={`mailto:${contact.attributes.email}`}
              sx={{ ml: 1 }}
              variant="body2"
            >
              {contact.attributes.email}
            </Link>
          </Box>
          <Box mt={1}>
            <Button
              onClick={() => onOpenContactForm(contact)}
              size="small"
              variant="contained"
            >
              Kontaktformular
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
}

const DISALLOWED_DOMAINS = [
  'example.org',
];
