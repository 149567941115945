import { RELATION_FETCH_LIMIT } from '@config';

const DEFAULT_PAGINATION_LIMIT = 100;

export function getGeneralProjectInfosSchema(options = {}) {
  const {
    fullSubProjects = false,
    projectAttributes = `
      name_de
      referenceId
      abbreviation
      projectType
      projectStart
      projectEnd
      fundingAmount
      fundingProfile
      projectVolume
      projectVolumeOverride
      performancePlanSystem
      website
      isShowNotesForSubProjects
    `,
  } = options;

  return `
    ${projectAttributes}
    ${getProjectLogo(options)}
    ${getProjectDescriptions(options)}
    ${getProjectLinks(options)}
    ${getProjectFiles(options)}
    ${getTags(options)}
    ${getOrganizations(options)}
    ${getContacts(options)}
    projects(
      sort: ["abbreviation"],
      pagination: { limit: ${DEFAULT_PAGINATION_LIMIT} }
    ) {
      data {
        id
        attributes {
          ${projectAttributes}
          ${getProjectLogo(options)}
          ${getProjectLinks(options)}
          ${getProjectFiles(options)}
          ${getTags(options)}
          ${getOrganizations(options)}
        }
      }
    }
    parent_project {
      data {
        id
        attributes {
          ${projectAttributes}
          ${getProjectLogo(options)}
          ${getProjectLinks(options)}
          ${getProjectFiles(options)}
          ${getTags(options)}
          projects {
            data {
              id
              ${fullSubProjects ? `
              attributes {
                ${projectAttributes}
                ${getProjectLogo(options)}
                ${getProjectDescriptions(options)}
                ${getProjectLinks(options)}
                ${getProjectFiles(options)}
                ${getTags(options)}
                ${getOrganizations(options)}
                ${getContacts(options)}
              }` : ''}
            }
          }
        }
      }
    }
  `;
}

function createOrganizationHierarchyUp(property, attributes, level = 0) {
  if (level === RELATION_FETCH_LIMIT) {
    return '';
  }

  return `${property} {
    data {
      id
      attributes {
        ${attributes}
        ${createOrganizationHierarchyUp('parent_organizations', attributes, level + 1)}
      }
    }
  }`;
}

function getTags(options) {
  // const { } = options;

  return `
    tag_items(
      pagination: { limit: ${DEFAULT_PAGINATION_LIMIT} }
    ) {
      data {
        id
        attributes {
          tag_item {
            data {
              id
            }
          }
        }
      }
    }
  `;
}

function getProjectDescriptions(options) {
  const {
    projectDescriptions = false,
  } = options;

  if (!projectDescriptions) {
    return '';
  }

  return `
    description1
    description2
    description3
    shortDescription
    project_short_descriptions(
      sort: ["index"],
      pagination: { limit: ${DEFAULT_PAGINATION_LIMIT} }
    ) {
      data {
        id
        attributes {
          name_de
        }
      }
    }
  `;
}

function getProjectLinks(options) {
  const {
    projectLinks = false,
  } = options;

  if (!projectLinks) {
    return '';
  }

  return `
    project_links(
      sort: ["index"],
      pagination: { limit: ${DEFAULT_PAGINATION_LIMIT} }
    ) {
      data {
        id
        attributes {
          name_de
          url
        }
      }
    }
  `;
}

function getProjectFiles(options) {
  const {
    projectFiles = false,
  } = options;

  if (!projectFiles) {
    return '';
  }

  return `
    project_files(
      sort: ["index"],
      pagination: { limit: ${DEFAULT_PAGINATION_LIMIT} }
      filters: { isPublic: { eq: true } }
    ) {
      data {
        id
        attributes {
          index
          title
          type
          file {
            data {
              id
              attributes {
                url
                formats
                name
                size
                width
                height
              }
            }
          }
        }
      }
    }
  `;
}

function getProjectLogo(options) {
  const {
    projectLogo = false,
  } = options;

  if (!projectLogo) {
    return '';
  }

  return `
    logo_default {
      data {
        id
        attributes {
          url
          name
          formats
        }
      }
    }
  `;
}

function getOrganizations(options) {
  const {
    fundingOrganization = false,
    finalBeneficiaryOrganization = false,
    executingOrganizations = false,
    projectManagerOrganization = false,
    projectSponsorOrganization = false,
    organizationTags = false,
  } = options;
  let {
    organizationAttributes = `
      organizationType
      publicationState
      abbreviation_de
      abbreviation_en
      addressZipCode
      addressCity
      isHistoryVisible
      isParentOrgHidden
      organization_histories(
        pagination: { limit: ${DEFAULT_PAGINATION_LIMIT} }
      ) {
        data {
          id
          attributes {
            date
            abbreviation_de
            abbreviation_en
          }
        }
      }
    `,
  } = options;

  if (organizationTags) {
    organizationAttributes += `\n${getTags(options)}`;
  }

  let result = '';

  if (fundingOrganization) {
    result += createOrganizationHierarchyUp('funding_org', organizationAttributes);
  }

  if (finalBeneficiaryOrganization) {
    result += createOrganizationHierarchyUp('final_beneficiary_org', organizationAttributes);
  }

  if (executingOrganizations) {
    result += createOrganizationHierarchyUp(
      'primary_executing_org',
      organizationAttributes,
    );
  }

  if (projectManagerOrganization) {
    result += createOrganizationHierarchyUp('project_manager_org', organizationAttributes);
  }

  if (projectSponsorOrganization) {
    result += createOrganizationHierarchyUp('project_sponsor_org', organizationAttributes);
  }

  return result;
}

function getContacts(options) {
  const {
    projectManagerContact = false,
    alternativeContact = false,
    contactAttributes = `
      isDeleted
      publicationState
      isActiveContact
      salutation
      title
      firstName
      lastName
      isNamePublic
      phone
      isPhonePublic
      email
      isEmailPublic
      employer {
        data {
          id
          attributes {
            name_de
            name_en
          }
        }
      }
    `,
  } = options;

  let result = '';

  if (projectManagerContact) {
    result += `
      project_manager {
        data {
          id
          attributes {
            ${contactAttributes}
          }
        }
      }
    `;
  }

  if (alternativeContact) {
    result += `
      alternative_contact {
        data {
          id
          attributes {
            ${contactAttributes}
          }
        }
      }
    `;
  }

  return result;
}
