import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Typography,
} from '@mui/material';
import { FiExternalLink } from 'react-icons/fi';
import { filesize } from 'filesize';
import { Fragment } from 'react';
import uniq from 'lodash/uniq';
import { DateTime } from 'luxon';

export default function ProjectAdditionalInformation({ t, project }) {
  const referenceIds = uniq([
    project.attributes.referenceId,
    ...project.attributes.projects.data.map((p) => p.attributes.referenceId),
  ]
    .filter(Boolean)
    .filter((r) => !r.startsWith('Wordpress')))
    .sort();

  return (
    <>
      {hasLinks(project) && (
        <>
          <Typography variant="h6">
            {t('modules.projects.components.projectDetails.views.ProjectAdditionalInformation.links')}
          </Typography>
          <List dense>
            {(project.attributes.website
              || project.attributes.project_links.data.length > 0) && (
              <>
                <ListSubheader sx={{ mb: -1 }}>
                  {t('modules.projects.components.projectDetails.views.ProjectAdditionalInformation.jointProjectTitle')}
                </ListSubheader>
                {project.attributes.website && (
                  <Link
                    href={project.attributes.website}
                    name={t(`modules.projects.components.projectDetails.views.ProjectAdditionalInformation.${project.attributes.projectType}`)}
                  />
                )}
                {project.attributes.project_links.data.map((link) => (
                  <Link
                    key={link.id}
                    href={link.attributes.url}
                    name={link.attributes.name_de}
                  />
                ))}
              </>
            )}
            {project.attributes.projects.data
              .filter((p) => p.attributes.project_links.data.length > 0
                || p.attributes.website)
              .map((p) => (
                <Fragment key={p.id}>
                  <ListSubheader sx={{ mb: -1 }}>
                    {`${t('modules.projects.components.projectDetails.views.ProjectAdditionalInformation.subprojectTitle')} ${p.attributes.abbreviation || ''}`}
                  </ListSubheader>
                  {p.attributes.website && (
                    <Link
                      key={[p.id]}
                      href={p.attributes.website}
                      name={`${t('modules.projects.components.projectDetails.views.ProjectAdditionalInformation.websiteSubproject')} ${p.attributes.abbreviation || ''}`}
                    />
                  )}
                  {p.attributes.project_links.data.map((link) => (
                    <Link
                      key={link.id}
                      href={link.attributes.url}
                      name={link.attributes.name_de}
                    />
                  ))}
                </Fragment>
              ))}
            {project.attributes.parent_project?.data?.attributes.website && (
              <Link
                href={project.attributes.website}
                name={t(`modules.projects.components.projectDetails.views.ProjectAdditionalInformation.${
                  project.attributes.parent_project?.data?.attributes.projectType
                }`)}
              />
            )}
            {project.attributes.parent_project?.data?.attributes.project_links.data.length > 0 && (
              <>
                <ListSubheader sx={{ mb: -1 }}>
                  {t('modules.projects.components.projectDetails.views.ProjectAdditionalInformation.jointProjectTitle')}
                </ListSubheader>
                {project.attributes.parent_project?.data?.attributes.project_links.data
                  .map((link) => (
                    <Link
                      key={link.id}
                      href={link.attributes.url}
                      name={link.attributes.name_de}
                    />
                  ))}
              </>
            )}
          </List>
        </>
      )}
      {referenceIds.length > 0
        && project.attributes.projectEnd
        && Math.abs(DateTime.fromISO(project.attributes.projectEnd).diffNow('months').months) >= 2
        && (
        <>
          <Typography mt={3} variant="h6">
            {t('modules.projects.components.projectDetails.views.ProjectAdditionalInformation.info')}
          </Typography>
          <List dense>
            {referenceIds.map((referenceId) => (
              <Link
                key={referenceId}
                href={
                  `https://www.tib.eu/de/suchen?tx_tibsearch_search%5Bquery%5D=${referenceId}&tx_tibsearch_search%5Bsrt%5D=rank&tx_tibsearch_search%5Bcnt%5D=20`
                }
                name={`${t('modules.projects.components.projectDetails.views.ProjectAdditionalInformation.referenceIdInfo')}'${referenceId}'`}
              />
            ))}
          </List>
        </>
        )}
      {hasDownloads(project) && (
        <>
          <Typography mt={3} variant="h6">
            {t('modules.projects.components.projectDetails.views.ProjectAdditionalInformation.downloads')}
          </Typography>
          <List dense>
            {filterFiles(project.attributes.project_files?.data).map((file) => (
              <Link
                key={file.id}
                href={file.attributes.file.data.attributes.url}
                info={`${file.attributes.title} (${filesize(file.attributes.file.data.attributes.size)})`}
                name={file.attributes.file.data.attributes.name}
              />
            ))}
            {project.attributes.projects.data
              .filter((p) => filterFiles(p.attributes.project_files.data).length > 0)
              .map((p) => (
                <Fragment key={p.id}>
                  <ListSubheader sx={{ mb: -1 }}>
                    {`${t('modules.projects.components.projectDetails.views.ProjectAdditionalInformation.subprojectTitle')} ${p.attributes.abbreviation || ''}`}
                  </ListSubheader>
                  {filterFiles(p.attributes.project_files.data).map((file) => (
                    <Link
                      key={file.id}
                      href={file.attributes.file.data.attributes.url}
                      info={`${file.attributes.title} (${filesize(file.attributes.file.data.attributes.size)})`}
                      name={file.attributes.file.data.attributes.name}
                    />
                  ))}
                </Fragment>
              ))}
            {filterFiles(
              project.attributes.parent_project?.data?.attributes.project_files?.data,
            ).length > 0 && (
              <>
                <ListSubheader sx={{ mb: -1 }}>
                  {t('modules.projects.components.projectDetails.views.ProjectAdditionalInformation.jointProjectTitle')}
                </ListSubheader>
                {filterFiles(
                  project.attributes.parent_project?.data?.attributes.project_files?.data,
                ).map((file) => (
                  <Link
                    key={file.id}
                    href={file.attributes.file.data.attributes.url}
                    info={`${file.attributes.title} (${filesize(file.attributes.file.data.attributes.size)})`}
                    name={file.attributes.file.data.attributes.name}
                  />
                ))}
              </>
            )}
          </List>
        </>
      )}
    </>
  );
}

function Link({ name, info, href }) {
  return (
    <ListItem disablePadding>
      <ListItemButton
        component="a"
        href={href}
        target="_blank"
      >
        <ListItemIcon>
          <FiExternalLink />
        </ListItemIcon>
        <ListItemText
          primary={name}
          primaryTypographyProps={{
            color: 'primary',
            variant: 'body2',
          }}
          secondary={info}
        />
      </ListItemButton>
    </ListItem>
  );
}

ProjectAdditionalInformation.tabFilter = (project) => true;
/* (
  hasLinks(project)
  || hasDownloads(project)
) */

function hasLinks(project) {
  return project.attributes.website
    || project.attributes.project_links.data.length > 0
    // Sub projects
    || project.attributes.projects.data.find((p) => p.attributes.website)
    || project.attributes.projects.data.find((p) => p.attributes.project_links.data.length > 0)
    // Parent project
    || project.attributes.parent_project?.data?.attributes.website
    || project.attributes.parent_project?.data?.attributes.project_links.data.length > 0;
}

function hasDownloads(project) {
  return filterFiles(project.attributes.project_files?.data).length > 0
    || project.attributes.projects.data
      .find((p) => filterFiles(p.attributes.project_files.data).length > 0)
    || filterFiles(
      project.attributes.parent_project?.data?.attributes.project_files?.data,
    ).length > 0;
}

function filterFiles(files) {
  if (!files) return [];

  return files.filter((file) => [
    'information',
    'other',
  ].includes(file.attributes.type));
}

const websiteLinkName = {
  individualProject: 'zum Internetauftritt des Projektes',
  subProject: 'zum Internetauftritt des Teilprojekts',
  jointProject: 'zum Internetauftritt des Verbundprojekts',
};
